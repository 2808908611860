.room-gallery-section {
  &__inner {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  &__left {
    flex-basis: 275px;
    margin-right: 30px;
    @media (max-width: 991px) {
      flex-basis: 100%;
      margin-right: 0;
      order: 1;
    }
  }
  &__description {
    background-color: #F5F5F5;
    padding: 25px 20px 65px;
    border-radius: 20px;
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 991px) {
      margin-bottom: 25px;
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__right {
    flex-basis: 870px;
    @media (max-width: 991px) {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
  .read-more-wrapper {
    height: 280px;
    overflow: hidden;
    position: relative;
    @media (max-width: 991px) {
      height: 200px;
    }
  }
  .read-more-toggle {
    cursor: pointer;
    padding: 20px 0 10px;
    background: linear-gradient(180deg, transparent 0%, #F5F5F5 40%);
    position: absolute;
    width: calc(100% - 40px);
    bottom: 20px;
  }
  .read-more-toggle-text {
    font-size: 16px;
    line-height: 24px;
    color: #0D8CC5;
    text-decoration: underline;
    text-decoration-style: dotted;
    text-underline-offset: 3px;
    display: inline;
    position: relative;
    z-index: 1;
  }
  .ug-gallery-wrapper .ug-grid-panel {
    @media (max-width: 991px) {
      background: none;
    }
  }
}