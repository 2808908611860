//===========//
//MIXIN FONTS
//===========//
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf) {
  $src: null;

  $extmods: (
    eot: "?"
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-display: swap;
    font-weight: $weight;
    src: $src;
  }
}

@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-Light", 300, normal);
@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-Regular", 400, normal);
@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-SemiBold", 600, normal);
@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-Bold", 700, normal);
@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-ExtraBold", 800, normal);
@include font-face("Gilroy", "../fonts/Gilroy/Gilroy-Black", 900, normal);

@font-face {
  font-family: 'Lato-Medium';
  src: url('../fonts/Lato-Medium.eot');
  src: local('Lato Medium'), local('Lato-Medium'),
  url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Medium.woff2') format('woff2'),
  url('../fonts/Lato-Medium.woff') format('woff'),
  url('../fonts/Lato-Medium.ttf') format('truetype'),
  url('../fonts/Lato-Medium.svg#Lato-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Semibold';
  src: url('../fonts/Lato-Semibold.eot');
  src: local('Lato Semibold'), local('Lato-Semibold'),
  url('../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Semibold.woff2') format('woff2'),
  url('../fonts/Lato-Semibold.woff') format('woff'),
  url('../fonts/Lato-Semibold.ttf') format('truetype'),
  url('../fonts/Lato-Semibold.svg#Lato-Semibold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-SemiboldItalic.eot');
  src: local('Lato Semibold Italic'), local('Lato-SemiboldItalic'),
  url('../fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-SemiboldItalic.woff2') format('woff2'),
  url('../fonts/Lato-SemiboldItalic.woff') format('woff'),
  url('../fonts/Lato-SemiboldItalic.ttf') format('truetype'),
  url('../fonts/Lato-SemiboldItalic.svg#Lato-SemiboldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Italic';
  src: url('../fonts/Lato-Italic.eot');
  src: local('Lato Italic'), local('Lato-Italic'),
  url('../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Italic.woff2') format('woff2'),
  url('../fonts/Lato-Italic.woff') format('woff'),
  url('../fonts/Lato-Italic.ttf') format('truetype'),
  url('../fonts/Lato-Italic.svg#Lato-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('../fonts/Lato-Bold.eot');
  src: local('Lato Bold'), local('Lato-Bold'),
  url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Bold.woff2') format('woff2'),
  url('../fonts/Lato-Bold.woff') format('woff'),
  url('../fonts/Lato-Bold.ttf') format('truetype'),
  url('../fonts/Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-ThinItalic';
  src: url('../fonts/Lato-ThinItalic.eot');
  src: local('Lato Thin Italic'), local('Lato-ThinItalic'),
  url('../fonts/Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-ThinItalic.woff2') format('woff2'),
  url('../fonts/Lato-ThinItalic.woff') format('woff'),
  url('../fonts/Lato-ThinItalic.ttf') format('truetype'),
  url('../fonts/Lato-ThinItalic.svg#Lato-ThinItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-BlackItalic';
  src: url('../fonts/Lato-BlackItalic.eot');
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
  url('../fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
  url('../fonts/Lato-BlackItalic.woff') format('woff'),
  url('../fonts/Lato-BlackItalic.ttf') format('truetype'),
  url('../fonts/Lato-BlackItalic.svg#Lato-BlackItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-MediumItalic';
  src: url('../fonts/Lato-MediumItalic.eot');
  src: local('Lato Medium Italic'), local('Lato-MediumItalic'),
  url('../fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-MediumItalic.woff2') format('woff2'),
  url('../fonts/Lato-MediumItalic.woff') format('woff'),
  url('../fonts/Lato-MediumItalic.ttf') format('truetype'),
  url('../fonts/Lato-MediumItalic.svg#Lato-MediumItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Light';
  src: url('../fonts/Lato-Light.eot');
  src: local('Lato Light'), local('Lato-Light'),
  url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Light.woff2') format('woff2'),
  url('../fonts/Lato-Light.woff') format('woff'),
  url('../fonts/Lato-Light.ttf') format('truetype'),
  url('../fonts/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-Hairline.eot');
  src: local('Lato Hairline'), local('Lato-Hairline'),
  url('../fonts/Lato-Hairline.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Hairline.woff2') format('woff2'),
  url('../fonts/Lato-Hairline.woff') format('woff'),
  url('../fonts/Lato-Hairline.ttf') format('truetype'),
  url('../fonts/Lato-Hairline.svg#Lato-Hairline') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-LightItalic';
  src: url('../fonts/Lato-LightItalic.eot');
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
  url('../fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-LightItalic.woff2') format('woff2'),
  url('../fonts/Lato-LightItalic.woff') format('woff'),
  url('../fonts/Lato-LightItalic.ttf') format('truetype'),
  url('../fonts/Lato-LightItalic.svg#Lato-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Regular';
  src: url('/fonts/Lato-Regular.eot');
  src: local('Lato Regular'), local('Lato-Regular'),
  url('/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/Lato-Regular.woff2') format('woff2'),
  url('../fonts/Lato-Regular.woff') format('woff'),
  url('../fonts/Lato-Regular.ttf') format('truetype'),
  url('../fonts/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Black';
  src: url('../fonts/Lato-Black.eot');
  src: local('Lato Black'), local('Lato-Black'),
  url('../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Black.woff2') format('woff2'),
  url('../fonts/Lato-Black.woff') format('woff'),
  url('../fonts/Lato-Black.ttf') format('truetype'),
  url('../fonts/Lato-Black.svg#Lato-Black') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-HeavyItalic';
  src: url('../fonts/Lato-HeavyItalic.eot');
  src: local('Lato Heavy Italic'), local('Lato-HeavyItalic'),
  url('../fonts/Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-HeavyItalic.woff2') format('woff2'),
  url('../fonts/Lato-HeavyItalic.woff') format('woff'),
  url('../fonts/Lato-HeavyItalic.ttf') format('truetype'),
  url('../fonts/Lato-HeavyItalic.svg#Lato-HeavyItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-BoldItalic';
  src: url('../fonts/Lato-BoldItalic.eot');
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
  url('../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
  url('../fonts/Lato-BoldItalic.woff') format('woff'),
  url('../fonts/Lato-BoldItalic.ttf') format('truetype'),
  url('../fonts/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-HairlineItalic.eot');
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
  url('../fonts/Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-HairlineItalic.woff2') format('woff2'),
  url('../fonts/Lato-HairlineItalic.woff') format('woff'),
  url('../fonts/Lato-HairlineItalic.ttf') format('truetype'),
  url('../fonts/Lato-HairlineItalic.svg#Lato-HairlineItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Heavy';
  src: url('../fonts/Lato-Heavy.eot');
  src: local('Lato Heavy'), local('Lato-Heavy'),
  url('../fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Heavy.woff2') format('woff2'),
  url('../fonts/Lato-Heavy.woff') format('woff'),
  url('../fonts/Lato-Heavy.ttf') format('truetype'),
  url('../fonts/Lato-Heavy.svg#Lato-Heavy') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Thin.eot');
  src: local('Lato Thin'), local('Lato-Thin'),
  url('../fonts/Lato-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Lato-Thin.woff2') format('woff2'),
  url('../fonts/Lato-Thin.woff') format('woff'),
  url('../fonts/Lato-Thin.ttf') format('truetype'),
  url('../fonts/Lato-Thin.svg#Lato-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro-LightItalic';
  src: url('../fonts/PFBeauSansPro-LightItalic.eot'),
  url('../fonts/PFBeauSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro-LightItalic.woff2') format('woff2'),
  url('../fonts/PFBeauSansPro-LightItalic.woff') format('woff'),
  url('../fonts/PFBeauSansPro-LightItalic.ttf') format('truetype'),
  url('../fonts/PFBeauSansPro-LightItalic.svg#Lato-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro-Bbook';
  src: url('../fonts/PFBeauSansPro-Bbook.eot'),
  url('../fonts/PFBeauSansPro-Bbook.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro-Bbook.woff2') format('woff2'),
  url('../fonts/PFBeauSansPro-Bbook.woff') format('woff'),
  url('../fonts/PFBeauSansPro-Bbook.ttf') format('truetype'),
  url('../fonts/PFBeauSansPro-Bbook.svg#Lato-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PFBeauSansPro-Light';
  src: url('../fonts/PFBeauSansPro-Light.eot'),
  url('../fonts/PFBeauSansPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/PFBeauSansPro-Light.woff2') format('woff2'),
  url('../fonts/PFBeauSansPro-Light.woff') format('woff'),
  url('../fonts/PFBeauSansPro-Light.ttf') format('truetype'),
  url('../fonts/PFBeauSansPro-Light.svg#Lato-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}


