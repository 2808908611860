.testimonials-wrapper {
  margin-bottom: 100px;
  &__items {
    margin-bottom: 30px;
  }
  .btns-wrapper {
    margin-bottom: 40px;
  }
}

.testimonials-item {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  &__body {
    background-color: #C3EDFF;
    border-radius: 20px;
    padding-bottom: 70px;
    display: flex;
    position: relative;
    z-index: 1;
    @media (max-width: 767px) {
      flex-direction: column;
      padding-bottom: 0;
    }
  }
  &__info {
    padding: 35px 35px 25px 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 300px;
    max-width: 300px;
    flex-shrink: 0;
    border-right: 2px solid $white;
    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: 100%;
      border-right: 0;
      border-bottom: 2px solid $white;
    }
    @media (max-width: 600px) {
      padding: 20px 15px;
    }
  }
  &__date {
    font-weight: 800;
    font-size: 15px;
    line-height: 26px;
    padding: 3px 10px;
    background: #FFFFFF;
    border-radius: 30px;
    margin-bottom: 12px;
  }
  &__name {
    font-size: 15px;
    line-height: 26px;
    text-transform: uppercase;
  }
  &__duration {
    font-weight: 300;
    font-size: 15px;
    line-height: 26px;
    color: #055EA0;
  }
  &__content {
    padding: 35px 45px 25px 35px;
    @media (max-width: 600px) {
      padding: 20px 15px;
    }
  }
  &__text {
    position: relative;
    margin-bottom: 15px;
    p {
      font-family: PFBeauSansPro-LightItalic, sans-serif;
      line-height: 22px;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        &:before {
          content: "";
          display: inline-block;
          background: url("../img/points.png");
          width: 32px;
          height: 23px;
          margin-right: 5px;
        }
      }
    }
    .read-more-wrapper {
      height: 140px;
      overflow: hidden;
      position: relative;
      &.opened {
        margin-bottom: 40px;
      }
    }
    .read-more-toggle {
      position: absolute;
      background: #C3EDFF;
      width: 250px;
      right: 0;
      bottom: -2px;
      padding: 2px 10px;
      @media (max-width: 991px) {
        width: 140px;
      }
      &.opened {
        width: auto;
        right: 10px;
        bottom: -28px;
      }
    }
    .read-more-toggle-text {
      color: #0D8CC5;
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }
  }
  &__box {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  &__images {
    display: flex;
    margin-right: 20px;
    @media (max-width: 991px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
    a {
      border-radius: 10px;
      overflow: hidden;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  }
  &__link {
    color: #0D8CC5;
    font-size: 15px;
    line-height: 24px;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-decoration-thickness: 1px;
    cursor: pointer;
    &:hover {
      text-decoration-color: transparent;
      text-decoration-style: dashed;
      text-decoration-thickness: 1px;
    }
  }
  &__answer {
    max-width: 1070px;
    margin-left: auto;
    background: #E1F6FF;
    border-radius: 0 0 20px 20px;
    padding: 20px 0 0 0;
    margin-top: -20px;

  }
  &__answer-content {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__answer-text {
    padding: 45px 35px;
    border-right: 2px solid $white;
    flex-grow: 1;
    @media (max-width: 767px) {
      padding-top: 15px;
    }
    @media (max-width: 600px) {
      padding: 20px 15px;
    }
  }
  &__answer-subtitle {
    flex-basis: 270px;
    max-width: 270px;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    padding: 0 30px;
    @media (max-width: 767px) {
      flex-basis: 100%;
      max-width: 100%;
      order: -1;
      padding-top: 25px;
    }
    @media (max-width: 600px) {
      padding: 25px 15px 0;
    }
  }
}