*, *:before, *:after {
  box-sizing: border-box;
}

body {

}

img {
  display: block;
  max-width: 100%;
}

svg {
  display: block;
}

.icon {
  width: 1em;
  height: 1em;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}

ul, ol, li, button {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: underline solid transparent;
  color: #000000;
  transition: 0.3s ease-in-out;
  &:hover {
    transition: 0.3s ease-in-out;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

*:focus {
  outline: none;
}

p {
  margin: 0 0 10px;
}

table {
  display: block;
}

tbody {
  display: block;
}

tr {
  display: block;
}

td {
  display: block;
}

.ruble {
  font-family: system,-apple-system,BlinkMacSystemFont,'Segoe UI','Segoe WP',Roboto,Ubuntu,Oxygen,Cantarell,'Fira Sans','Helvetica Neue',Helvetica,'Lucida Grande','Droid Sans',Tahoma,'Microsoft Sans Serif',sans-serif;
}