.close-cross {
  position: absolute;
  svg {
    width: 25px;
    height: 25px;
    fill: none;
    cursor: pointer;
  }
}

section:last-child {
  margin-bottom: 120px;
}

.bg-wrapper {
  width: 100%;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &--no-of {
    img {
      object-fit: initial;
    }
  }
}

.text-white {
  color: $white !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.h1-common {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 10px;
  font-family: Gilroy, sans-serif;
  color: #055EA0;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.h2-common {
  font-family: Gilroy, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 45px;
  color: #055EA0;
  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

.mt-6 {
  margin-top: 60px;
}

.btn-rdsgn {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  color: $black;
  padding: 20px 40px;
  margin: 0 10px;
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: .2s ease-in-out;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 17px;
    padding: 15px 30px;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    transition: .2s ease-in-out;
  }
  &--white {
    background-color: $white;
    color: $black;
    &:hover {
      text-decoration: none;
      background-color: $bgblue;
      color: $white;
    }
  }
  &--blue {
    background-color: $bgblue;
    color: $white;
    border: 1px solid transparent;
    &:hover {
      text-decoration: none;
      background-color: $white;
      color: $black;
      border: 1px solid #469AD9;
    }
  }
  &--border-blue {
    border: 1px solid #469AD9;
  }
  &--medium {
    padding: 12px 20px;
  }
}

.btns-wrapper {
  display: flex;
  align-items: flex-start;
  &--center {
    justify-content: center;
  }
  &--mobile-center {
    @media (max-width: 600px) {
      align-items: center;
    }
  }
  @media (max-width: 600px) {
    flex-direction: column;
    margin: -7px 0;
  }
  .btn-rdsgn {
    @media (max-width: 600px) {
      margin: 7px 0;
    }
  }
}

.btn.white {
  background-color: $white;
  border-color: $white;
  color: #000000;
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
  svg {
    width: 70px;
    height: 70px;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    width: 40px;
    height: 75px;
    @media (max-width: 991px) {
      width: 20px;
      height: 20px;
    }
  }
  &--small {
    width: 45px;
    height: 45px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 14px;
      height: 24px;
      position: relative;
      right: 3px;
      top: 1px;
    }
  }
  &--prev {
    left: -110px;
    @media (max-width: 1450px) {
      left: -80px
    }
    @media (max-width: 1365px) {
      display: none;
    }
    @media (max-width: 1199px) {
      left: 10px;
      display: block;
    }
    &.arrow--small {
      left: -15px;
    }
  }
  &--next {
    right: -110px;
    transform: translateY(-50%) scaleX(-1);
    @media (max-width: 1450px) {
      right: -80px;
    }
    @media (max-width: 1365px) {
      display: none;
    }
    @media (max-width: 1199px) {
      right: 10px;
      display: block;
    }
    &.arrow--small {
      right: -15px;
    }
  }
}

.swiper-pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 2;
  display: flex;
}

.swiper-pagination-bullet {
  width: 30px;
  height: 3px;
  background: rgba(179, 221, 241, 0.5);
  border-radius: 10px;
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.swiper-pagination-bullet-active {
    background-color: $white;
  }
}

.breadcrumbs a {
  color: #0a8dc4;
}

.common-ol {
  counter-reset: num;
  margin-top: 30px;
  padding: 0 0 0 20px;
  display: block;
  list-style: none;
  overflow: hidden;
  li {
    margin-bottom: 15px;
    padding-left: 20px;
    font-family: Lato-Regular, sans-serif;
    font-size: 17px;
    line-height: 24px;
    list-style: outside none none;
    position: relative;
    &:before {
      background: #c3edff none repeat scroll 0 0;
      border-radius: 50%;
      color: #0560a2;
      content: counter(num, decimal) ". ";
      counter-increment: num;
      font-family: Lato-Heavy, sans-serif;
      font-size: 17px;
      font-style: normal;
      height: 22px;
      left: -12px;
      line-height: 21px;
      padding: 0 0;
      position: absolute;
      top: 0;
      width: 22px;
    }
  }
}
.price-table {
  border: 1px solid #C3ECFE;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  @media (max-width: 600px) {
    width: 550px;
  }
  thead {
    display: table-header-group;
  }
  tbody {
    display: table-row-group;
  }
  tr {
    display: table-row;
    &:nth-child(2n) {
      td {
        background-color: #EEFAFF;
      }
    }
  }
  th {
    font-style: italic;
    font-size: 11px;
    line-height: 13px;
    color: #0560A2;
    padding: 15px 10px;
    text-align: center;
    &:last-child {
      padding-right: 50px;
    }
  }
  td {
    display: table-cell;
    padding: 15px 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #0560A2;
    text-align: center;
    &:first-child {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #055EA0;
      text-align: left;
    }
    &:last-child {
      padding-right: 50px;
    }
  }
}

.sidebar-layout-wrapper {
  max-width: 1200px;
  padding: 15px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.sidebar {
  flex-basis: 270px;
  margin-right: 30px;
  background: #FAFAFA;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 0;
  flex-shrink: 0;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0;
    overflow-x: auto;
  }
  &__nav-menu {
    display: flex;
    flex-direction: column;
    @media (max-width: 991px) {
      flex-direction: row;
    }
  }
  &__nav-menu-item {
    margin: 0 20px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid #EAEAEA;
    @media (max-width: 991px) {
      padding: 7px 15px;
      margin: 0;
      border-top: 0;
      flex-shrink: 0;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      padding: 5px 10px;
    }
    &:hover {
      color: #055EA0;
      background-color: #E1F6FF;
      margin: 0;
      padding: 10px 20px;
      text-decoration-color: transparent;
    }
    &:first-child {
      border-top: 0;
    }
    &.current {
      color: #055EA0;
      background-color: #E1F6FF;
      margin: 0;
      padding: 10px 20px;
      border-color: transparent;
      font-weight: 700;
      @media (max-width: 991px) {
        padding: 7px 15px;
        border-top: 0;
        margin: 0;
      }
      @media (max-width: 767px) {
        padding: 5px 10px;
      }
      &:hover {
        text-decoration-color: transparent;
      }
      & + .sidebar__nav-menu-item {
        border-top: 0;
      }
    }
  }
}

.content-wrapper {
  flex-basis: 860px;
  max-width: 860px;
  @media (max-width: 991px) {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.typography {
  p {
    color: #666666;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
    font-family: Lato-Regular, sans-serif;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .h2 {
    color: #055EA0;
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    font-family: Gilroy, sans-serif;
    margin: 30px 0 20px;
  }
  ul {
    list-style: initial;
    padding-left: 25px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    li {
      font-family: Lato-Regular, sans-serif;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
    }
  }
  .table-wrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  table {
    margin-bottom: 30px;
    width: 100%;
    display: table;
    @media (max-width: 767px) {
      width: 735px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    th {
      display: table-cell;
      font-size: 17px;
      line-height: 24px;
      color: #FFFFFF;
      font-family: Lato-Regular, sans-serif;
      padding: 10px 20px;
      background: #0c84b6;
      text-align: center;
      &:first-child {
        text-align: left;
      }
    }
    thead {
      display: table-header-group;
    }
    tbody {
      display: table-row-group;
    }
    tr {
      display: table-row;
      &:nth-child(2n) {
        td {
          background: #eefaff;
        }
      }
    }
    td {
      font-family: Lato-Light, sans-serif;
      font-size: 16px;
      display: table-cell;
      text-align: center;
      padding: 15px 20px;
      color: #0560a2;
      &:first-child {
        text-align: left;
      }
    }
  }
}

.page-topper {
  padding: 60px 0 40px;
  h1 {
    margin-bottom: 20px;
  }
}

.page-wrapper--pages {
  padding: 50px 0;
}

.page-wrapper--testimonials {
  padding: 0 0 110px;
}

.pagination-rdsgn {
  display: flex;
  justify-content: center;
  &__item {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 12px 17px;
    background: #EAEAEA;
    border-radius: 10px;
    margin-right: 8px;
    transition: .2s ease-in-out;
    &:last-child {
      margin-right: 0;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 16px;
      padding: 10px 14px;
    }
    &:hover {
      background: #0A8DC4;
      color: $white;
      text-decoration-color: transparent;
      transition: .2s ease-in-out;
    }
    &.active {
      background: #0A8DC4;
      color: $white;
    }
  }
}

.page-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  a {
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.arcticmodal-container_i {
  tbody {
    height: 100vh;
  }
  tr {
    height: 100%;
  }
}

.arcticmodal-container_i2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modalWin {
    background-color: #fff;
    padding: 60px 40px 40px 40px;
    max-width: 530px;
    margin: 0 auto;
    border-radius: 15px;
    width: 100%;
    position: relative;
    .formSentContent {
      text-align: center;
    }
  }
}

.fancybox-close-small:after {
  display: none;
}

.fancybox-button--arrow_left.fancybox-button div {
  position: relative;
  left: 14px;
}

.fancybox-button--arrow_right.fancybox-button div {
  position: relative;
  right: 6px;
}