.advantages {
  padding-bottom: 90px;
  @media (max-width: 767px) {
    padding-bottom: 45px;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -25px -10px;
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
  &__item {
    flex-basis: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 10px;
  }
  &__item-icon {
    width: 98px;
    height: 98px;
    margin-bottom: 16px;
  }
  &__item-text {
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: $black;
  }
}