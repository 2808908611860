.main-banner {
  position: relative;
  padding: 200px 0 220px;
  @media (max-width: 767px) {
    padding: 50px 0 60px;
  }
  &__content {
    position: relative;
    z-index: 1;
  }
  &__subtitle {
    font-size: 45px;
    line-height: 55px;
    color: $white;
    font-family: Gilroy, sans-serif;
    margin-bottom: 35px;
    max-width: 520px;
    @media (max-width: 767px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
}