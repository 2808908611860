.food {
  margin-bottom: 90px;
  padding-top: 60px;
  &__inner {
    display: flex;
    @media (max-width: 1199px) {
      flex-direction: column;
    }
  }
  &__left {
    flex-basis: 850px;
    max-width: 850px;
    margin-right: 40px;
    @media (max-width: 1199px) {
      margin-right: 0;
      margin-bottom: 40px;
      flex-basis: 100%;
      max-width: 100%;
    }
    p {
      font-size: 17px;
      line-height: 24px;
      margin: 0 0 20px;
      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__slider {
    position: relative;
    margin-bottom: 60px;
    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__slider-inner {
    overflow: hidden;
    width: 100%;
  }
  &__slide {
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 135px;
      object-fit: cover;
      @media (max-width: 991px) {
        width: 100%;
      }
      @media (max-width: 420px) {
        height: 235px;
      }
    }
  }
  &__right {
    flex-basis: 245px;
    max-width: 245px;
    @media (max-width: 1199px) {
      display: flex;
      flex-basis: 100%;
      max-width: 100%;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
  &__menu-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    transition: .2s ease-in-out;
    @media (max-width: 1199px) {
      margin-bottom: 0;
      margin-right: 20px;
    }
    @media (max-width: 600px) {
      margin-bottom: 20px;
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration-color: transparent;
      .food__menu-item-text {
        text-decoration-color: transparent;
        transition: .2s ease-in-out;
      }
    }
  }
  &__menu-item-icon {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C3ECFE;
    margin-right: 15px;
    flex-shrink: 0;
    svg {
      width: 24px;
      height: 37px;
    }
  }
  &__menu-item-text {
    font-family: Gilroy, serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #0D8CC5;
    transition: .2s ease-in-out;
  }

}